import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";

export const sectionService = {
    get,
    getById,
    create,
    update,
    active,
    desactive

};


async function getById(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/sections/${id}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function get() {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/sections`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function create(events) {
    try {
        let ret = await axiosInstance.post(`${baseUrl}/sections`, events);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function update(events, id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/sections/${id}`, events);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}



async function active(id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/sections/${id}/activate`);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function desactive(id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/sections/${id}/desactivate`);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}