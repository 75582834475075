<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { morceauxService } from '../../../../services/morceaux.service';
import { playlistService } from '../../../../services/playlist.service';
import { albumService } from '../../../../services/album.service';
import { artisteService } from '../../../../services/artiste.service';
import { sectionService } from '../../../../services/section.service';
import { filesBaseUrl } from '../../../../constants/config';
import { customFunction } from '../../../../utils/customFunction';
import moment from 'moment';
import {
    required,
} from "vuelidate/lib/validators";
import VsToast from '@vuesimple/vs-toast';
import Multiselect from "vue-multiselect";

export default {
    page: {
        title: "Sections",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, Multiselect },
    data() {
        return {
            playlists: [],
            morceaux: [],
            artistes: [],
            albums: [],
            sections: [],
            items: [],
            filesBaseUrl,
            title: "Sections",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "libelle",
            sortDesc: false,
            fields: [
                { key: "libelle", label: 'Libellé', sortable: true },
                { key: "morceaux", label: "Total Morceaux", sortable: true },
                { key: "artistes", label: "Total Artistes", sortable: true },
                { key: "albums", label: "Total Albums", sortable: true },
                { key: "playlists", label: "Total Playlists", sortable: true },
                { key: "activated", label: "Etat", sortable: true },
                { key: "options", sortable: false, thClass: !this.verifAdmin() ? 'd-none' : '', tdClass: !this.verifAdmin() ? 'd-none' : '' }
            ],
            form: {
                libelle: "",
                morceaux: [],
                artistes: [],
                playlists: [],
                albums: [],
            },
            submitted: false,
            showForm: false,
            imagePlaylist: null,
            isUpdate: false,
            updateId: null,
        };
    },
    validations: {
        form: {
            libelle: { required }
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.sections.length;
        }
    },
    mounted() {
        this.getSections();
        this.getMorceaux();
        this.getPlaylist();
        this.getAlbums();
        this.getArtistes();
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getSections() {
            this.sections = await sectionService.get();
        },
        async getMorceaux() {
            this.morceaux = await morceauxService.get();
        },
        async getArtistes() {
            this.artistes = await artisteService.get();
        },
        async getAlbums() {
            this.albums = await albumService.get();
        },
         async getPlaylist() {
            this.playlists = await playlistService.get();
        },
        verifAdmin() {
            return customFunction.verifAdmin();
        },

        hideForm() {
            this.showForm = false;
            this.submitted = false;
            this.isUpdate = false;
            this.form.libelle = '';
            this.form.morceaux = [];
            this.form.artistes = [];
            this.form.albums = [];
            this.form.playlists = [];
            this.imagePlaylist = null;
        },
        formateDateForm(d) {
            return moment(d).format('YYYY-MM-DD');
        },
        async showUpdate(item) {
            this.showForm = true;
            this.isUpdate = true;
            this.updateId = item.id;
            this.form.libelle = item.libelle;
            this.form.morceaux = item.morceauxs;
            this.form.artistes = item.artistes;
            this.form.albums = item.albums;
            this.form.playlists = item.playlists;
            this.imagePlaylist = null;
        },
        async formSubmit() {
            this.submitted = true
            this.$v.$touch();
            //console.log(this.form);
            if (!this.$v.$error) {

                let forms = {
                    libelle: this.form.libelle,
                    artistes: [],
                    morceaux: [],
                    albums: [],
                    playlists: []
                };

                this.form.morceaux.forEach(e => {
                    forms.morceaux.push(e.id);
                })
                this.form.artistes.forEach(e => {
                    forms.artistes.push(e.id);
                })
                this.form.albums.forEach(e => {
                    forms.albums.push(e.id);
                })
                this.form.playlists.forEach(e => {
                    forms.playlists.push(e.id);
                })

                const rep = this.isUpdate ? await sectionService.update(forms, this.updateId) : await sectionService.create(forms);

                if (rep >= 400) {

                    VsToast.show({
                        title: 'Veuillez ressayer',
                        message: 'Une erreur s\'est produite',
                        variant: 'danger',
                    })

                } else {

                    VsToast.show({
                        title: this.isUpdate ? 'Mise à jour effectué' : 'Enregistrement effectué',
                        message: '',
                        variant: 'success',
                    });

                    this.sections = await sectionService.get();
                    this.hideForm();
                }

            }
        },
        async desactivate(id) {
            const resp = await sectionService.desactive(id);
            if (resp.status >= 400) {
                VsToast.show({
                    title: 'Une erreur s\'est produite! Veuillez réssayer plus tard',
                    message: '',
                    variant: 'error',
                });
            } else {
                VsToast.show({
                    title: 'Section désactivé de la plateforme',
                    message: '',
                    variant: 'success',
                });
                this.getSections();
            }
        },
        async activate(id) {
            const resp = await sectionService.active(id);
            if (resp.status >= 400) {
                VsToast.show({
                    title: 'Une erreur s\'est produite! Veuillez réssayer plus tard',
                    message: '',
                    variant: 'error',
                });
            } else {
                VsToast.show({
                    title: 'Section disponible sur plateforme',
                    message: '',
                    variant: 'success',
                });
                this.getSections();
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div v-if="!showForm && verifAdmin()" class="col-md-8">
                <button @click="showForm = true" class="btn btn-primary">Ajouter</button>
            </div>
        </div>
        <form v-if="showForm" class="needs-validation mb-2" @submit.prevent="formSubmit">
            <div class="row">
                <div class="col-md-12">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Libellé</label>
                        <input id="validationCustom01" v-model="form.libelle" type="text" class="form-control"
                            placeholder="Libellé" value="" :class="{
                                'is-invalid': submitted && $v.form.libelle.$error,
                            }" />
                        <div v-if="submitted && $v.form.libelle.$error" class="invalid-feedback">
                            <span v-if="!$v.form.libelle.required">Champs requis.</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Morceaux</label>
                        <multiselect v-model="form.morceaux" track-by="id" :options="morceaux" label="libelle"
                            :multiple="true"></multiselect>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Artistes</label>
                        <multiselect v-model="form.artistes" track-by="id" :options="artistes" label="nom_scene"
                            :multiple="true"></multiselect>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Album</label>
                        <multiselect v-model="form.albums" track-by="id" :options="albums" label="libelle"
                            :multiple="true"></multiselect>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Playlists</label>
                        <multiselect v-model="form.playlists" track-by="id" :options="playlists" label="libelle"
                            :multiple="true"></multiselect>
                    </div>
                </div>

            </div>

            <button v-if="!isUpdate" class="btn btn-primary" type="submit">Enregistrer</button>
            <button v-if="isUpdate" class="btn btn-primary" type="submit">Mettre à jour</button>
            <button @click="hideForm()" class="btn btn-secondary" style="margin-left: 3px;" type="submit">Annuler</button>
        </form>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0 datatables">
                            <b-table :items="sections" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                             
                                <template v-slot:cell(morceaux)="r">
                                    <span class="badge bg-primary"> {{ r.item.morceauxs.length }}</span>
                                </template>
                                <template v-slot:cell(artistes)="r">
                                    <span class="badge bg-primary"> {{ r.item.artistes.length }}</span>
                                </template>
                                <template v-slot:cell(albums)="r">
                                    <span class="badge bg-primary"> {{ r.item.albums.length }}</span>
                                </template>
                                <template v-slot:cell(playlists)="r">
                                    <span class="badge bg-primary"> {{ r.item.playlists.length }}</span>
                                </template>
                                <template v-slot:cell(activated)="r">
                                    <span style="margin-right: 3px;"
                                        :class="r.item.activated ? 'badge bg-success' : 'badge bg-danger'"
                                        > {{ r.item.activated ? 'Activé' : 'Désactivé' }}</span>
                                </template>
                                <template v-slot:cell(options)="r">
                                    <button v-if="verifAdmin()" @click="showUpdate(r.item)" title="Mettre à jour" class="btn btn-warning btn-sm" style="margin-right: 5px;"><i class="fa fa-edit"></i></button>
                                    <button v-if="r.item.activated && verifAdmin()" @click="desactivate(r.item.id)" title="Désactiver" class="btn btn-danger btn-sm"><i class="fa fa-lock"></i></button>
                                    <button v-if="!r.item.activated && verifAdmin()" @click="activate(r.item.id)" title="Activer" class="btn btn-success btn-sm"><i class="fa fa-unlock"></i></button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>